<template>
  <a-drawer
    width="800"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <a-descriptions bordered :column="2" size="middle">
      <a-descriptions-item label="上报图片" :span="2" v-if="form.imageList && form.imageList.length > 0">
        <viewer :images="form.imageList">
          <img v-for="item in form.imageList" style="width:50px;margin-right:10px" :src="item" :key="item">
        </viewer>
      </a-descriptions-item>
      <a-descriptions-item label="设备类型">{{ form.typeName }}</a-descriptions-item>
      <a-descriptions-item label="设备名称">{{ form.devName }}</a-descriptions-item>
      <a-descriptions-item label="设备编号">{{ form.devCode }}</a-descriptions-item>
      <a-descriptions-item label="水表编号">{{ form.devSn }}</a-descriptions-item>

      <a-descriptions-item label="绑定人名称">{{ form.userName }}</a-descriptions-item>
      <a-descriptions-item label="绑定人手机号">{{ form.userPhone }}</a-descriptions-item>

      <a-descriptions-item label="状态">
        <a-tag color="orange" v-if="form.faultStatus == 0">待处理</a-tag>
        <a-tag color="green" v-else-if="form.faultStatus == 1">处理成功</a-tag>
        <a-tag color="red" v-else-if="form.faultStatus == 2">拒绝处理</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="故障类型">{{ form.faultTypeName }}</a-descriptions-item>
      <a-descriptions-item label="处理说明" :span="2">{{ form.rejectCause }}</a-descriptions-item>

      <a-descriptions-item label="是否生成退款订单">
        <a-tag color="blue" v-if="form.sfRefundOrder == 0">否</a-tag>
        <a-tag color="orange" v-else-if="form.sfRefundOrder == 1">是</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="订单号">{{ form.orderNo }}</a-descriptions-item>
      <a-descriptions-item label="退款金额">{{ form.refundAmount }}</a-descriptions-item>

      <a-descriptions-item label="添加时间">{{ form.createTime }}</a-descriptions-item>
      <a-descriptions-item label="备注">{{ form.remark }}</a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>

import { faultInfo } from '@/api/pages/fault'

export default {
  name: 'CreateForm',
  props: {
  },
  data () {
    return {
      submitLoading: false,
      form: {},
      open: false
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    info (id) {
      faultInfo({ id }).then(response => {
        this.form = response.data
        this.form.imageList = response.data.imgUrls ? response.data.imgUrls.split(',') : []
        console.log(this.form.imageList)
        this.open = true
        // this.$nextTick(() => {
        //   this.$refs.map.mapInit(response.data.log, response.data.lat)
        // })
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
